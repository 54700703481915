var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useState } from 'react';
import { t } from '../../../../assets/i18n/i18n';
import { formatCurrency } from '../self-publishing/PublicationSettings/CurrencyUtils';
import { icons, PaymentMethod } from './types';
import mixam from '../../../boot/mixam.js';
import Moment from 'react-moment';
import OrderInvoice from "./OrderInvoice";
var orderPaymentRemove = function (orderId, paymentId) { return __awaiter(void 0, void 0, void 0, function () {
    var error_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, fetch("/api/orders/".concat(orderId, "/payments/").concat(paymentId), {
                        method: 'DELETE'
                    })];
            case 1:
                _a.sent();
                window.location.reload();
                return [3 /*break*/, 3];
            case 2:
                error_1 = _a.sent();
                console.error(error_1);
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); };
var OrderPayments = function (_a) {
    var orderId = _a.orderId;
    var _b = useState(), paymentSummary = _b[0], setPaymentSummary = _b[1];
    var _c = useState(), deletingPaymentId = _c[0], setDeletingPaymentId = _c[1];
    useEffect(function () {
        (function () { return __awaiter(void 0, void 0, void 0, function () {
            var response, data, error_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        return [4 /*yield*/, fetch("/api/orders/".concat(orderId, "/payments"))];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.json()];
                    case 2:
                        data = _a.sent();
                        setPaymentSummary(data);
                        return [3 /*break*/, 4];
                    case 3:
                        error_2 = _a.sent();
                        console.error(error_2);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); })();
    }, [orderId]);
    var renderPaymentMethodCell = function (payment) {
        var _a;
        var paymentMethod = payment.paymentMethod, vendorTxCode = payment.vendorTxCode, id = payment.id;
        var customText = (_a = {},
            _a[PaymentMethod.ACCOUNT] = "CRD-".concat(id),
            _a[PaymentMethod.PROFORMA] = "PROFORMA-".concat(id),
            _a[PaymentMethod.BANK] = vendorTxCode && vendorTxCode.trim() !== "" ? vendorTxCode : "BTF-".concat(id),
            _a);
        var text = customText[paymentMethod] !== undefined ? customText[paymentMethod] : vendorTxCode;
        // If no icon mapping exists, return a plain cell.
        if (!icons[paymentMethod]) {
            return React.createElement("td", null, vendorTxCode);
        }
        // Build title using i18n's t() with a dynamic key.
        var title = t("orders.payment.paymentMethodTitle.".concat(paymentMethod));
        return (React.createElement("td", null,
            React.createElement("span", { title: title },
                React.createElement("i", { className: "fa ".concat(icons[paymentMethod]) })),
            text));
    };
    if ((paymentSummary === null || paymentSummary === void 0 ? void 0 : paymentSummary.payments.length) === 0) {
        return React.createElement(React.Fragment, null);
    }
    return paymentSummary ? (React.createElement("div", null,
        React.createElement("table", { className: "table tbl-payments" },
            React.createElement("colgroup", null, paymentSummary.orderLocked === false ? (React.createElement(React.Fragment, null,
                React.createElement("col", { style: { width: '35%' } }),
                React.createElement("col", { style: { width: '5%' } }),
                React.createElement("col", { style: { width: '15%' } }),
                React.createElement("col", { style: { width: '15%' } }),
                React.createElement("col", { style: { width: '15%' } }),
                React.createElement("col", { style: { width: '15%' } }))) : (React.createElement(React.Fragment, null,
                React.createElement("col", { style: { width: '40%' } }),
                React.createElement("col", { style: { width: '15%' } }),
                React.createElement("col", { style: { width: '15%' } }),
                React.createElement("col", { style: { width: '15%' } }),
                React.createElement("col", { style: { width: '15%' } })))),
            React.createElement("thead", null,
                React.createElement("tr", null,
                    React.createElement("th", null, t('orders.payment.transactionNumber')),
                    paymentSummary.orderLocked === false && React.createElement("th", null),
                    React.createElement("th", null, t('orders.payment.transactionDate')),
                    React.createElement("th", null, t('orders.payment.transactionType')),
                    React.createElement("th", null, t('orders.payment.transactionStatus')),
                    React.createElement("th", null, t('orders.payment.transactionAmount')))),
            React.createElement("tbody", null, paymentSummary.payments.map(function (payment) {
                // Replace "TOKEN" with "COUPON" in the transaction type.
                var transactionType = payment.transactionType
                    ? payment.transactionType.replace(/TOKEN/g, 'COUPON')
                    : '';
                // Use capturedAmount if provided and non-zero; otherwise, fall back to payment.amount.
                var capturedAmount = payment.capturedAmount && payment.capturedAmount !== 0
                    ? payment.capturedAmount
                    : payment.amount;
                return (React.createElement("tr", { key: payment.id, className: "type-".concat(transactionType) },
                    renderPaymentMethodCell(payment),
                    paymentSummary.orderLocked === false && (React.createElement("td", null, payment.paymentMethod === PaymentMethod.ACCOUNT && (React.createElement("button", { "data-style": "zoom-out", type: "button", className: "btn btn-product-3 btn-anime btn-anime-zoomout", title: "Remove this payment", onClick: function () {
                            setDeletingPaymentId(payment.id);
                            orderPaymentRemove(orderId, payment.id);
                        } }, deletingPaymentId === payment.id ?
                        React.createElement("span", null,
                            React.createElement("i", { className: "fa fa-spinner fa-lg fa-spin" }))
                        :
                            React.createElement("span", { className: "btn-anime-label" },
                                React.createElement("i", { className: "fa fa-times-circle" })))))),
                    React.createElement("td", null, payment.date && (React.createElement(React.Fragment, null,
                        React.createElement("span", null,
                            React.createElement(Moment, { format: "lll" }, payment.date))))),
                    React.createElement("td", null, transactionType),
                    React.createElement("td", null, payment.status),
                    React.createElement("td", { className: "text-right" },
                        transactionType === 'REFUND' && '-',
                        formatCurrency(capturedAmount, mixam.shop.languageTag))));
            }))),
        paymentSummary.invoices && paymentSummary.invoices.length > 0 &&
            React.createElement(OrderInvoice, { invoices: paymentSummary.invoices, invoiceUrls: paymentSummary.invoiceUrls, accountingServiceType: paymentSummary.accountingServiceType }),
        paymentSummary.refunds.length > 0 && (React.createElement("div", null,
            React.createElement("h3", null, t('orders.payment.refunds')),
            React.createElement("table", { className: "table" },
                React.createElement("thead", null,
                    React.createElement("tr", null,
                        React.createElement("th", null, t('orders.payment.invoice')),
                        React.createElement("th", null, t('orders.payment.creditDate')),
                        React.createElement("th", null, t('orders.payment.creditNote')),
                        React.createElement("th", null, t('orders.payment.refundAmount')),
                        React.createElement("th", null, t('orders.payment.refundTaxAmount')))),
                React.createElement("tbody", null, paymentSummary.refunds.map(function (refund) { return (React.createElement("tr", { key: refund.invoiceId },
                    React.createElement("td", null,
                        React.createElement("a", { href: refund.invoiceUrl, target: "_blank", rel: "noopener noreferrer" },
                            refund.invoiceNumber,
                            ' ',
                            React.createElement("i", { className: "fa fa-external-link", "aria-hidden": "true" }))),
                    React.createElement("td", null,
                        React.createElement(Moment, { format: "lll" }, refund.creditDate)),
                    React.createElement("td", null,
                        React.createElement("a", { href: refund.creditNoteUrl, target: "_blank", rel: "noopener noreferrer" },
                            refund.creditNoteNumber,
                            ' ',
                            React.createElement("i", { className: "fa fa-external-link", "aria-hidden": "true" }))),
                    React.createElement("td", null, formatCurrency(refund.refundAmount, mixam.shop.languageTag)),
                    React.createElement("td", null, formatCurrency(refund.refundTaxAmount, mixam.shop.languageTag)))); }))))))) : (React.createElement("div", null, "Loading..."));
};
export default OrderPayments;
