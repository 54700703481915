var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState } from 'react';
import { Formik, Field, ErrorMessage } from 'formik';
import mixam from '../../../../../boot/mixam.js';
import { submitApproval } from './api/InvoiceRefunds';
import { formatCurrency } from '../../../../react/self-publishing/PublicationSettings/CurrencyUtils';
import { Badge, Button, Modal as BSModal, Modal } from 'react-bootstrap';
import { LoadingSpiral } from "../FormikTableModal/styled";
import { t } from "../../../../../../assets/i18n/i18n";
var InvoiceRefundApproval = function (_a) {
    var invoiceRefund = _a.invoiceRefund;
    var _b = useState(false), open = _b[0], setOpen = _b[1];
    // @ts-ignore
    var isAllowed = (mixam.user.hasRole('ROLE_DEV') || mixam.user.hasRole('ROLE_CREDIT_CONTROL'))
        && invoiceRefund.status.value === 'PENDING_APPROVAL';
    var comparisonLines = invoiceRefund.lineItems.map(function (lineItem, index) {
        var originalInvoiceLineItem = invoiceRefund.originalInvoiceLineItems[index];
        return __assign(__assign({}, lineItem), { originalQuantity: originalInvoiceLineItem.quantity, originalDescription: originalInvoiceLineItem.description, originalAmount: originalInvoiceLineItem.unitAmount, originalTaxAmount: originalInvoiceLineItem.taxAmount });
    });
    var validateApprovalStatus = function (target) {
        return !['APPROVED', 'REJECTED'].includes(target)
            ? 'Please select an option'
            : null;
    };
    return (React.createElement("div", null,
        React.createElement(Button, { variant: invoiceRefund.status.value === 'PENDING_APPROVAL' ? 'primary' : 'secondary', className: "btn-sm w-100", onClick: function () { return setOpen(true); } }, invoiceRefund.status.value === 'PENDING_APPROVAL' ? t('admin.refund.update') : t('admin.refund.view')),
        React.createElement(Modal, { show: open, onHide: function () { return setOpen(false); }, size: comparisonLines.length > 0 ? 'xl' : "lg" },
            React.createElement(Formik, { initialValues: {
                    refundMethod: invoiceRefund.refundMethod,
                    notes: invoiceRefund.notes,
                    approvalStatus: invoiceRefund.status.value,
                }, onSubmit: function (values) { return __awaiter(void 0, void 0, void 0, function () {
                    var error_1;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                _a.trys.push([0, 2, , 3]);
                                return [4 /*yield*/, submitApproval(invoiceRefund.invoiceNumber, {
                                        id: invoiceRefund.id,
                                        status: values.approvalStatus,
                                        method: values.refundMethod,
                                        notes: values.notes,
                                    })];
                            case 1:
                                _a.sent();
                                setOpen(false);
                                window.dispatchEvent(new CustomEvent('showFixedMessage', {
                                    detail: { message: { type: 'info', body: 'Refund request updated', interval: 15000 } }
                                }));
                                return [3 /*break*/, 3];
                            case 2:
                                error_1 = _a.sent();
                                window.dispatchEvent(new CustomEvent('showFixedMessage', {
                                    detail: { message: { type: 'error', body: "Error updating data: ".concat(error_1.message), interval: 15000 } }
                                }));
                                return [3 /*break*/, 3];
                            case 3: return [2 /*return*/];
                        }
                    });
                }); } }, function (_a) {
                var submitForm = _a.submitForm, values = _a.values, isSubmitting = _a.isSubmitting;
                return (React.createElement("div", null,
                    React.createElement(BSModal.Header, { closeButton: true },
                        React.createElement(BSModal.Title, { style: { marginRight: '1rem' } }, "".concat(t('admin.refund.requested')),
                            " "),
                        React.createElement(Badge, { bg: invoiceRefund.status.variant, style: { textWrap: 'wrap' } }, invoiceRefund.status.text)),
                    React.createElement(BSModal.Body, null,
                        React.createElement("div", null,
                            React.createElement("p", null,
                                invoiceRefund.raisedBy,
                                " has requested a",
                                ' ',
                                invoiceRefund.wholeInvoice ? 'full' : 'partial',
                                " refund of",
                                ' ', "".concat(formatCurrency(invoiceRefund.refundAmount +
                                invoiceRefund.refundTaxAmount, mixam.shop.languageTag)),
                                invoiceRefund.refundTaxAmount
                                    ? " (plus ".concat(formatCurrency(invoiceRefund.refundTaxAmount, mixam.shop.languageTag), " tax)")
                                    : null,
                                ' on invoice',
                                ' ',
                                React.createElement("strong", null, invoiceRefund.invoiceNumber)),
                            comparisonLines.length > 0 && (React.createElement("table", { className: "table table-striped" },
                                React.createElement("thead", null,
                                    React.createElement("tr", null,
                                        React.createElement("th", null, t('admin.refund.quantity')),
                                        React.createElement("th", null, t('admin.refund.desc')),
                                        React.createElement("th", null, t('admin.refund.amount')),
                                        React.createElement("th", null, t('admin.refund.tax')),
                                        React.createElement("th", null, t('admin.refund.refundAmount')),
                                        React.createElement("th", null, t('admin.refund.refundTax')))),
                                React.createElement("tbody", null, comparisonLines.map(function (lineItem, index) { return (React.createElement("tr", { key: index },
                                    React.createElement("td", null, lineItem.originalQuantity),
                                    React.createElement("td", null, lineItem.originalDescription),
                                    React.createElement("td", null, formatCurrency(lineItem.originalAmount || 0, mixam.shop.languageTag)),
                                    React.createElement("td", null, formatCurrency(lineItem.originalTaxAmount || 0, mixam.shop.languageTag)),
                                    React.createElement("td", null, formatCurrency(lineItem.refundAmount || 0, mixam.shop.languageTag)),
                                    React.createElement("td", null, formatCurrency(lineItem.refundTaxAmount || 0, mixam.shop.languageTag)))); })))),
                            values.refundMethod === 'ORIGINAL_PAYMENT_METHOD' ? (React.createElement("div", { className: "alert alert-warning", role: "alert" }, "".concat(t('admin.refund.manualStep'), " ").concat(t("paymentMethodEnumMap.".concat(invoiceRefund.originalPaymentMethodType))))) : (React.createElement("div", { className: "alert alert-info", role: "alert" }, t('admin.refund.storeCreditWillBeApplied'))),
                            React.createElement("div", { className: "form-group mb-3" },
                                React.createElement("label", { htmlFor: "approvalStatus" }, t('admin.refund.markPaymentAs')),
                                React.createElement(Field, { className: "form-select", as: "select", name: "approvalStatus", disabled: !isAllowed, validate: validateApprovalStatus },
                                    React.createElement("option", { value: "PENDING_APPROVAL" }, t('admin.refund.choose')),
                                    React.createElement("option", { value: "APPROVED" }, t('admin.refund.approved')),
                                    React.createElement("option", { value: "REJECTED" }, t('admin.refund.rejected'))),
                                React.createElement(ErrorMessage, { name: "approvalStatus" }, function (msg) { return React.createElement("div", { className: "text-danger" }, msg); })),
                            React.createElement("div", { className: "form-group mb-3" },
                                React.createElement("label", { htmlFor: "refundMethod" }, t('admin.refund.refundMethodLabel')),
                                React.createElement(Field, { className: "form-select", as: "select", name: "refundMethod", disabled: !isAllowed },
                                    React.createElement("option", { value: "ORIGINAL_PAYMENT_METHOD" }, t('admin.refund.originalPaymentMethod')),
                                    React.createElement("option", { value: "STORE_CREDIT" }, t('admin.refund.storeCredit'))),
                                React.createElement(ErrorMessage, { name: "refundMethod" }, function (msg) { return React.createElement("div", { className: "text-danger" }, msg); })),
                            React.createElement("div", { className: "form-group mb-3" },
                                React.createElement("label", { htmlFor: "notes" }, t('admin.refund.notes')),
                                React.createElement(Field, { disabled: !isAllowed, className: "form-control", as: "textarea", name: "notes" }),
                                React.createElement(ErrorMessage, { name: "notes" }, function (msg) { return React.createElement("div", { className: "text-danger" }, msg); })))),
                    React.createElement(BSModal.Footer, null,
                        React.createElement(Button, { variant: "secondary", onClick: function () { return setOpen(false); }, disabled: isSubmitting }, t('admin.refund.close')),
                        isAllowed && React.createElement(Button, { variant: "primary", onClick: submitForm, disabled: isSubmitting }, isSubmitting ? React.createElement(LoadingSpiral, null) : t('admin.refund.saveChanges')))));
            }))));
};
export default InvoiceRefundApproval;
